import React, { useState, useCallback } from "react";
import { useMediaQuery } from "react-responsive";
import { Chevron } from "../../../assets/icons";
import "../../../styles/components/Slices/SectionKusama.scss";
import CustomButton from "../../CustomButton";
import MouseTrackerTooltip from "../../MouseTrackerTooltip";
import VideoComponent from "../../VideoComponent";


const SectionKusama = ({ slice }) => {
  const items = slice?.items;

  const isDesktop = useMediaQuery({ minWidth: 901 });
  const isTablet = useMediaQuery({ minWidth: 640, maxWidth: 900 });
  const getInitialHoveredIndex = () => {
    return isTablet && slice?.items?.length > 0 ? 0 : null;
  };

  const [hoveredIndex, setHoveredIndex] = useState(getInitialHoveredIndex);
  const [videoIndex, setVideoIndex] = useState(0);

  const handleMouseEnter = useCallback((index) => {
    setHoveredIndex(index);
    setVideoIndex(index);
  }, []);

  const handleMouseLeave = useCallback(() => {
    setHoveredIndex(null);
    setVideoIndex(0);
  }, []);

  return (
    <section className="flex flex-col justify-center max-w-6xl px-4 py-10 mx-auto xl:px-0 sm:px-12">
      <article className="flex flex-col items-center space-y-4 sm:justify-center">
        <div className="flex justify-center gap-2.5 items-center">
          <img className="w-10" src={slice?.primary?.icon?.url} />
          <p
            className={`text-2.5xl sm:text-3.25xl font-manrope font-bold tracking-tightest_7 text-purple-1100`}
          >
            {slice?.primary?.section_title?.text}
          </p>
        </div>
        <p
          className={`text-sm font-normal text-gray-600 sm:text-base tracking-tight_1`}
        >
          {slice?.primary?.descriptor_text?.text}
        </p>
        <CustomButton
          icon={<Chevron />}
          text={slice?.primary?.cta_title?.text}
          link={slice?.primary?.cta_link?.url}
          buttonClass={`bg-gray-1600 hover:bg-gray-2900`}
          direction={`right`}
        />
      </article>
      <article className="mt-10 sm:p-5 lg:p-7 flex flex-col gap-5 rounded-[14px] w-full sm:bg-white">
        <div className="flex gap-3 overflow-x-auto sm:justify-center md:gap-5 scroll-smooth no-scrollbar sm:overflow-hidden">
          {slice?.items?.map((value, index) => {
            const isHovered = hoveredIndex === index;
            const iconUrl =
              isHovered && value?.hovered_icon?.url
                ? value.hovered_icon.url
                : !isDesktop
                  ? value.hovered_icon.url
                  : value?.icon?.url;

            const Wrapper =
              (value?.cta_link?.url && isTablet) || isDesktop ? "a" : "div";
            const wrapperProps = value?.cta_link?.url
              ? { href: `${value.cta_link.url}` }
              : {};
            return (
              <Wrapper
                {...wrapperProps}
                className={`sm:px-[22px] bg-white transition-all sm:max-w-[298px] lg:max-w-[530px] relative ease-in-out duration-200 group border-[1px] border-white  ${
                  isTablet && isHovered
                    ? "kusama_card_tablet"
                    : isHovered && isDesktop
                      ? "kusama_card"
                      : "rounded-2xl"
                } sm:py-6 relative`}
                key={index}
                onMouseEnter={() => handleMouseEnter(index)}
                onMouseLeave={handleMouseLeave}
              >
                {value?.show_cta && isDesktop && isHovered && (
                  <MouseTrackerTooltip
                    className={`bg-purple-1500 `}
                    offset={{ x: 10, y: 10 }}
                  >
                    {value?.cta_text?.text}
                  </MouseTrackerTooltip>
                )}
                <img
                  className="min-h-[236px] flex sm:hidden min-w-[238px] bg-gray-1600 rounded-tl-2xl rounded-tr-2xl"
                  src={value?.mobile_card_image?.url}
                />
                <div className="flex flex-col px-3.5 py-5 sm:px-0 sm:py-0 sm:flex-row items-baseline gap-2">
                  <img className="min-w-6" src={iconUrl} />
                  <div className="flex flex-col gap-3">
                    <p
                      className={`${
                        isHovered
                          ? "text-purple-1100"
                          : !isDesktop && !isTablet
                            ? "text-purple-1100"
                            : "text-gray-600"
                      } text-2xl sm:text-[20px] lg:text-2xl tracking-tightest_5 font-manrope font-semibold`}
                    >
                      {value?.title?.text}
                    </p>
                    <p
                      className={`${
                        isHovered
                          ? "text-gray-2500"
                          : !isDesktop && !isTablet
                            ? "text-gray-2500"
                            : "text-gray-600"
                      }  sm:text-sm lg:text-base tracking-tight_1`}
                    >
                      {value?.descriptor_text?.text}
                    </p>
                  </div>
                  {value?.cta_link?.url && (
                    <CustomButton
                      text={value?.cta_text?.text}
                      link={value?.cta_link?.url}
                      buttonClass={`bg-gray-1600 mt-2 flex sm:hidden`}
                      icon={<Chevron />}
                      direction={`right`}
                    />
                  )}
                </div>
              </Wrapper>
            );
          })}
        </div>
        {items[videoIndex]?.video_link?.url || items[videoIndex]?.image?.url ? (
          <div className="hidden px-5 py-5 rounded-xl sm:flex bg-gray-1500 md:px-7 md:py-7">
            {items[videoIndex]?.video_link?.url ? (
              <div className="items-center bg-gray-1500 rounded-xl">
                <VideoComponent
                  loop
                  autoPlay
                  muted
                  key={items[videoIndex]?.video_link?.url}
                  className="w-full sm:min-h-[334px] lg:min-h-[603px] h-auto mx-auto rounded-2xl"
                >
                  <source
                    src={items[videoIndex]?.video_link?.url}
                    type="video/mp4"
                  />
                </VideoComponent>
              </div>
            ) : (
              <img
                className="video_asset"
                src={items[videoIndex]?.image?.url}
              />
            )}
          </div>
        ) : null}
      </article>
    </section>
  );
};

export default SectionKusama;
