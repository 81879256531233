import React from "react";
import CustomButton from "../CustomButton";
import desktopBG from "../../assets/backgrounds/emailBannerBG.png";
import tabletBG from "../../assets/backgrounds/tabletEmailBanner.png";
import mobileBG from "../../assets/backgrounds/mobileEmailBannerBG.png";
import SingleEmailForm2 from "../Pieces/SingleEmailForm2";

const AnnotateFooter = ({
  heading,
  description,
  openModal,
  setEmail,
  bgClass = "footer_bg_image",
  imageBG = false,
}) => {
  const dark = "annotate_footer";
  return (
    <section
      className={`relative w-full mb-10 lg:px-32 z-10 lg:py-20 sm:px-9 px-4 py-16 sm:mx-auto text-center text-[#FAFAFA] rounded-2.5xl ${dark} shadow-card  max-w-7xl cta_bg border ${bgClass}`}
    >
      {imageBG && (
        <>
          <img
            src={desktopBG}
            className="h-full w-full absolute inset-0 -z-10 rounded-2.5xl hidden md:block"
            alt="desktopBG"
          />
          <img
            src={tabletBG}
            className="h-full w-full absolute inset-0 -z-10 rounded-2.5xl hidden sm:block md:hidden"
            alt="tabletBG"
          />
          <img
            src={tabletBG}
            className="h-full w-full absolute inset-0 -z-10 rounded-2.5xl block sm:hidden"
            alt="tabletBG"
          />
        </>
      )}
      <div className="flex flex-col justify-center w-full max-w-5xl ">
        <h6 className="text-2xl font-semibold mx-auto font-manrope sm:text-3.25xl px-5 sm:px-24 lg:px-0 lg:text-4.5xl max-w-[700px] leading-8 sm:leading-10 lg:tracking-tightest_4 lg:leading-12 ">
          {heading}
        </h6>

        <p className="mb-5 sm:mb-7 mt-3.5 sm:mt-6 text-sm sm:text-lg text-gray-2200 md:px-5 leading-5 sm:leading-7 max-w-[720px] mx-auto sm:px-10 lg:px-0">
          {description}
        </p>

        <section className="flex justify-center">
          <SingleEmailForm2 border={dark} setEmail={setEmail} openModal={openModal} buttonText={`Book a demo`} />
        </section>
      </div>
    </section>
  );
};

export default AnnotateFooter;
