import slugify from "slugify";
import { blockedDomains } from "../constants";
import * as Yup from "yup";

/**
 * This method capitalize first letter from the give string
 * @param {String} string
 * @returns String with the first letter capital
 */
export function capitalizeFirstLetter(string) {
  return string ? string.charAt(0).toUpperCase() + string.slice(1) : "";
}

/**
 * This method remove the trailing slash
 * @param {String} str
 * @returns String after removing trailing slash if exists
 */
export function removeTrailingSlash(str) {
  return str && str?.endsWith("/") ? str?.slice(0, -1) : str;
}

function escapeRegExp(str) {
  return str?.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
}

/**
 * Sanitizes a string to be used as a search pattern in a regular expression.
 * @param {string} searchText - The text to be sanitized.
 * @returns {string} - The sanitized text.
 */
export const sanatizeSearchText = (searchText) => {
  // The dynamic text to search for
  let _searchText = searchText; // or searchText = "";

  // Check if the searchText contains only a single question mark
  if (_searchText?.length === 1 && _searchText === "?") {
    // Construct a regular expression pattern that matches a literal question mark
    _searchText = "\\?";
  } else {
    // Escape the special characters in the searchText
    _searchText = escapeRegExp(_searchText);
  }
  return _searchText;
};

export const _slugify = (text) => {
  return slugify(text, { remove: /[&,+()$~%.'":*?<>{}]/g });
};

/**
 * Returns an array of slices from the given data that match the specified key.
 * If no matches are found, returns an empty array.
 *
 * @param {object} data - The data object containing the slices.
 * @param {string} key - The slice type key to filter by.
 * @returns {Array} - An array of matching slices or an empty array if no matches are found.
 */
export const pickSlice = (data, key) => {
  return data?.body?.filter((v) => v.slice_type === key) || [[]];
};

/**
 * Append the target="_blank" attribute to anchor tags in the HTML string.
 * @param {string} htmlString - The HTML string to modify.
 * @returns {string} The modified HTML string.
 */
export function appendTargetBlank(htmlString) {
  const anchorTagRegex = /<a(.*?)>/g;
  const modifiedHTML = htmlString.replace(anchorTagRegex, (match, group) => {
    if (group.includes('target="_blank"')) {
      return match;
    } else {
      return `<a${group} target="_blank">`;
    }
  });

  return modifiedHTML;
}

/**
 * Replaces occurrences of `{{video::URL}}` in the input text with an HTML video tag.
 * The video tag includes the provided URL as the video source with attributes for looping, autoplay, and muting.
 *
 * @param {string} inputText - The input text containing `{{video::URL}}` placeholders.
 * @returns {string} The input text with `{{video::URL}}` placeholders replaced by the corresponding HTML video tag.
 *
 * @example
 * const inputText = '{{video::https://prismic-io.s3.amazonaws.com/encord/fceb243f-eb02-4b0a-8ce4-693504b978f8_Video+Annotation+Landing+Page+Hero.mp4}}';
 * const result = replaceVideoTags(inputText);
 * console.log(result);
 * // Output:
 * // "<video loop autoPlay muted><source src='https://prismic-io.s3.amazonaws.com/encord/fceb243f-eb02-4b0a-8ce4-693504b978f8_Video+Annotation+Landing+Page+Hero.mp4' type='video/mp4'></video>"
 */
export function replaceVideoTags(inputText) {
  const regexPattern = /\{\{video::(.*?)\}\}/g;
  return inputText.replace(
    regexPattern,
    '<video loop autoPlay muted><source src="$1" type="video/mp4"></video>'
  );
}

/**
 * Generates SEO schema for a blog based on the provided information.
 *
 * @param {Object} options - The options object.
 * @param {Object} options.location - The location object containing information about the article's URL.
 * @param {Object} options.author - The author object containing information about the article's author.
 * @param {Object} options.blog - The blog object containing information about the article's content.
 * @returns {string} - The JSON string representing the SEO schema for the article.
 *
 * @example
 * const seoSchema = generateSeoSchema({
 *   location: { origin: 'https://example.com', href: 'https://example.com/blog/post-1' },
 *   author: { data: { full_name: { text: 'John Doe' }, avatar: { url: 'https://example.com/avatar.jpg', dimensions: { width: 100, height: 100 } } }, uid: 'john-doe' },
 *   blog: { first_publication_date: '2023-01-01T00:00:00Z', last_publication_date: '2023-01-02T12:00:00Z', data: { title: { text: 'Article Title' }, image: { url: 'https://example.com/article-image.jpg', dimensions: { width: 800, height: 600 } }, meta_description: { text: 'Description of the article.' } }, tags: ['tag1', 'tag2'] }
 * });
 */
export function generateSeoSchema({ location, author, blog }) {
  // Extracted LinkedIn URL
  const regex = /<a\s+(?:[^>]*\s+)?href="([^"]*)"/i;
  const match = author?.data?.bio?.html.match(regex);
  const linkedInURL = match ? match[1] : null;
  return JSON.stringify(
    {
      "@context": "https://schema.org",
      "@type": "Article",
      publisher: {
        "@type": "Organization",
        name: "Encord Blog",
        url: "https://encord.com/blog/",
        logo: {
          "@type": "ImageObject",
          url: "https://encord.cdn.prismic.io/encord/96ceee40-26a8-48ca-b3fa-67918caaef80_Group.svg?fit=max",
        },
      },
      author: {
        "@type": "Person",
        name: author?.data?.full_name?.text,
        image: {
          "@type": "ImageObject",
          url: author?.data?.avatar?.url,
          width: author?.data?.avatar?.dimensions?.width,
          height: author?.data?.avatar?.dimensions?.height,
        },
        url: `${location?.origin}/${author.uid}`,
        sameAs: [linkedInURL],
      },
      headline: blog.data.title.text,
      url: location?.href,
      datePublished: blog?.first_publication_date,
      dateModified: blog?.last_publication_date,
      image: {
        "@type": "ImageObject",
        url: blog?.data?.image?.url,
        width: blog?.data?.image?.dimensions?.width,
        height: blog?.data?.image?.dimensions?.height,
      },
      keywords: blog?.data?.keywords?.text,
      description: blog?.data?.meta_description?.text,
      mainEntityOfPage: location?.href,
    },
    null,
    " "
  );
}

export const checkFutureTime = (time) => {
  if (!time) {
    return false;
  }
  const currentTime = new Date();
  const startTime = new Date(time);
  return startTime > currentTime;
};

// Custom validation function
export const emailDomainValidation = () => {
  return Yup.string().test(
    "email-domain",
    "Enter your work email",
    function (value) {
      if (!value) return true;

      const email = value.trim();
      const domain = email.slice(email.lastIndexOf("@") + 1);

      return !blockedDomains.includes(`@${domain}`);
    }
  );
};

/**
 * Checks if an email address is associated with an educational institution based on its domain.
 * @param {string} email - The email address to check.
 * @returns {boolean} Returns true if the email address is associated with an educational institution, false otherwise.
 */
export function isEducationalEmail(email) {
  const eduPattern = /\.edu/i;
  const acPattern = /\.ac/i;
  const domain = email?.split("@").pop();

  if (eduPattern.test(domain) || acPattern.test(domain)) {
    return true;
  }
  return false;
}

/**
 * @summary  Define the regular expression pattern to match {{some_text}} and {{some_text_2}}
 * @param {string} htmlString
 * @returns string
 */
export function removeExpressions(htmlString) {
  var regex = /{{[^{}]+}}/g;

  // Replace all matches with an empty string
  return htmlString?.replace(regex, "");
}

/**
 * This method parse the URL
 * @param {String} str
 * @returns String after splitting the URL and return lastSegment of URL
 */
export function parseURL(url) {
  const parsedUrl = new URL(url);
  const segments = parsedUrl.pathname.split('/');
  const lastSegment = segments.filter(segment => segment !== "").pop();
  return lastSegment;
}

const htmlTagRegex = /<\/?[^>]+(>|$)/;

// Regex to match JavaScript patterns (e.g., <script>, javascript:, onload, etc.)
const jsRegex =
  /<script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script>|javascript:|on\w+\s*=/i;

// Regex to allow alphanumeric characters and certain special characters
const allowedCharsRegex = /^[a-zA-Z0-9\s]*$/;

// This regex will now allow alphanumeric characters, spaces, and the specified special characters (-, ,, ?, .,‘, !, “, @, &, $, (), ;, :, /, -, #, %,).
const allowedSpecialCharsRegex = /^[a-zA-Z0-9\s\-\‘!"@&$();:/#%?,.]*$/

// Combined validation function to check both regexes
const noHtmlOrJs = (value) => {
  return (
    !htmlTagRegex.test(value) &&
    !jsRegex.test(value) &&
    allowedCharsRegex.test(value)
  );
};

// Combined validation function to check both regexes
const combinedValidation = (value) => {
  return (
    // !htmlTagRegex.test(value) &&
    // !jsRegex.test(value) &&
    allowedSpecialCharsRegex.test(value)
  );
};

export const FIRST_NAME_VALIDATION = Yup.string()
  .min(2, "First name must be at least 2 characters")
  .max(20, "First name cannot exceed 20 characters")
  .required("First name is required")
  .test(
    "no-html-or-js",
    "Please avoid using HTML tags, JavaScript or special characters.",
    noHtmlOrJs
  );

export const LAST_NAME_VALIDATION = Yup.string()
  .min(2, "Last name must be at least 2 characters")
  .max(20, "Last name cannot exceed 20 characters")
  .required("Last name is required")
  .test(
    "no-html-or-js",
    "Please avoid using HTML tags, JavaScript or special characters.",
    noHtmlOrJs
  );

export const PHONE_VALIDATION = Yup.string()
  .nullable(true)
  .test(
    "no-html-or-js",
    "Please avoid using HTML tags, JavaScript or special characters.",
    noHtmlOrJs
  );

export const USE_CASE_TEXT_VALIDATION = Yup.string()
  .required("Required")
  .test(
    "no-html-or-js",
    "Please avoid using HTML tags, JavaScript or special characters.",
    noHtmlOrJs
  );

export const OPTIONAL_VALIDATION = Yup.string().test(
  "no-html-or-js",
  "Please avoid using HTML tags, JavaScript or special characters.",
  noHtmlOrJs
);

export const WORK_EMAIL_VALIDATION = emailDomainValidation()
  .email("Invalid work email address")
  .required("Email is required");

export const COMPANY_NAME_VALIDATION = Yup.string()
  .min(2, "Company name must be at least 2 characters")
  .max(20, "Company name cannot exceed 20 characters")
  .required("Company name is required")
  .test(
    "no-html-or-js",
    "Please avoid using HTML tags, JavaScript or special characters.",
    noHtmlOrJs
  );

export const HOW_CAN_WE_HELP_VALIDATION = Yup.string()
  .min(2, "This Field must have at least 2 characters")
  .required("This Field is required")
  ;
