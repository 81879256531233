import React from "react";

const ImageMonet = ({ slice }) => {
  const imageUrl = slice?.primary?.image?.url;
  return (
    <>
      {imageUrl && (
        <section className="relative px-4 mx-auto sm:px-12 lg:px-5 max-w-7xl">
          <article className="relative lg:mx-11 ">
            <div className="items-center mx-auto w-fit">
              <img src={imageUrl} alt="Image Monet" />
            </div>
          </article>
        </section>
      )}
    </>
  );
};
export default ImageMonet;
