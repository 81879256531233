import React from "react";
const VinciTeamMemberCard = ({ authordata }) => {

  return (
    <section className="bg-white py-3.5 px-5 z-10 rounded-[14px] lg:rounded-xl sm:min-w-[280px] w-full sm:w-auto sm:max-w-[287px]">
      <div className="flex py-1.5 items-center gap-2.5 ">
        <img
          className="rounded-full size-20"
          alt="author-avatar-url"
          src={authordata?.member_image?.url}
        />
        <div className="flex flex-col gap-2">
          <p className="tracking-[-0.9px] font-manrope text-gray-2700 text-lg font-semibold">
            {authordata?.member_name?.text}
          </p>
          {authordata?.member_position?.text && (
            <p className="tracking-[-0.7px] font-inter text-gray-600 text-sm font-normal">
              {authordata?.member_position?.text}
            </p>
          )}
        </div>
      </div>
    </section>
  );
};

export default VinciTeamMemberCard;
