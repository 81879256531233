import React from "react";
import CustomButton from "../CustomButton";
import { Chevron } from "../../assets/icons";
import { Link } from "gatsby-link";

const CustomerVerticalCard = ({ data, descriptionClass }) => {
  const VerticalCardIcons = [
    {
      icon: "https://encord.cdn.prismic.io/encord/83549f38-37be-426c-a312-5107f575c736_testing.svg?fit=max",
    },
    {
      icon: "https://encord.cdn.prismic.io/encord/684dae60-2526-4403-8005-847e6b65480b_annotate-icon.svg?fit=max",
    },
    {
      icon: "https://encord.cdn.prismic.io/encord/fe00a610-84b5-4bed-987a-0fe349e61c74_productIcons.svg?fit=max",
    },
  ];

  const sortedItems = [...data.items].sort((a) => {
    return a.card_label?.text === `Index` ? -1 : 0;
  });

  return (
    <section>
      <section className="flex justify-start">
        <p className="text-purple-1100 pb-5 sm:pb-10 text-2xl sm:text-3.25xl font-manrope text-left tracking-tighter_3 font-semibold sm:tracking-[-1.8px] max-w-60 sm:max-w-full">
          {data?.primary?.section_heading?.text}
        </p>
      </section>

      <div className="flex flex-row gap-6 overflow-x-auto HideScrollBar scroll-smooth cs_slide_opacity_right">
        {sortedItems.map((item, index) => (
          <a
            href={item?.cta_link?.url}
            key={index}
            className="customer_story_card flex flex-col p-5 sm:p-7 justify-between lg:max-w-[368px] lg:min-w-[368px] min-w-[360px] max-w-[360px] sm:min-w-[564px] rounded-2xl sm:rounded-2.5xl border dark:border-[#f0f0f04d] border-gray-1600 bg-white dark:bg-[#ffffff0d]"
          >
            <div className="flex flex-col gap-3.5 md:gap-4">
              <img
                src={item?.card_image?.url}
                alt="cart-image"
                className="rounded-xl sm:rounded-2xl min-w-[320px] sm:min-w-[476px] lg:max-w-[312px] lg:min-w-[312px]"
              />
              <div className="flex flex-row gap-1.5 mt-1 sm:mt-6 items-center">
                <img
                  src={VerticalCardIcons[index].icon}
                  width={28}
                  height={28}
                  alt="img"
                />
                <p className="text-base sm:text-xl font-medium text-gray-2500 tracking-[-0.8px]">
                  {item?.card_label?.text}
                </p>
              </div>

              <h1
                className={`font-manrope tracking-[-1.2px] mx-0 max-w-[476px] ${
                  index == 2 ? "lg:max-w-[275px]" : "lg:max-w-[312px]"
                } sm:tracking-tighter_3 mt-1 leading-9 font-semibold text-2xl sm:text-2.5xl text-purple-1100 dark:text-white`}
              >
                {item?.card_heading?.text}
              </h1>
              <p
                className={`text-sm sm:text-lg lg:text-base leading-6 text-gray-2500 dark:text-gray-2200 font-normal tracking-tighter_3 font-inter ${descriptionClass}`}
              >
                {item?.card_description?.text}
              </p>
            </div>
            <CustomButton
              text={item?.cta_text?.text}
              icon={<Chevron fill={`purple`} />}
              link={item?.cta_link?.url}
              direction={"right"}
              buttonClass={`bg-gray-1600 mt-5 hover:bg-gray-2900 dark:hover:bg-purple-1100 text-gray-2500 text-base dark:bg-[#9c8cff4d] dark:!text-purple-1900 tracking-tighter_2`}
            />
          </a>
        ))}
      </div>
    </section>
  );
};

export default CustomerVerticalCard;
