import React, { useContext, useEffect, useState } from "react";
import ModalContext from "../../../Contexts/openModal";
import darkVector from "../../../assets/backgrounds/HeroRaphael/darkVector.png";
import darkVectorMobile from "../../../assets/backgrounds/HeroRaphael/darkVectorMobile.png";
import darkVectorTablet from "../../../assets/backgrounds/HeroRaphael/darkVectorTablet.png";
import lightVector from "../../../assets/backgrounds/HeroRaphael/lightVector.png";
import "../../../styles/components/Slices/heroMonet.scss";
import "../../../styles/components/Slices/heroRaphael.scss";
import CustomButton from "../../CustomButton";
import LogoMarquee from "../../LogoMarquee";
import { SingleEmailForm } from "../../Pieces/SingleEmailForm";
import VideoComponent from "../../VideoComponent";
import SingleEmailForm2 from "../../Pieces/SingleEmailForm2";

const HeroRaphael = ({ slice }) => {
  const { toggleModal ,setEmail } = useContext(ModalContext);
  const [isEmpty, setIsEmpty] = useState(false);
  const [leftBottom, setLeftBottom] = useState(true);
  const [rightBottom, setRightBottom] = useState(false);
  useEffect(() => {
    if (slice?.primary?.trusted_by_logo_alignment === "empty") {
      setIsEmpty(true);
    }
    if (slice?.primary.trusted_by_logo_alignment === "left bottom") {
      setLeftBottom(true);
    }
    if (slice?.primary.trusted_by_logo_alignment === "right bottom") {
      setRightBottom(true);
    }
  }, [isEmpty, leftBottom, rightBottom]);

  const logo_marque_data = {
    items: slice.items.map((x) => ({
      brand_image: { ...x.trusted_by_logo },
    })),
  };
  const dark = slice.primary?.theme === "dark";

  return (
    <>
      <article
        className={`overflow-hidden h-full  pb-20 xs:pb-0 relative pt-16 ${logo_marque_data?.items[0]?.brand_image?.url ? "xs:h-[680px] sm:h-[680px] lg:h-[821px] custom_height" : "xs:h-[680px] sm:h-[580px] lg:h-[700px] xl:h-[821px]"} ${slice.primary?.theme == "dark" && "slice_background"}`}
      >
        {/* desktop vector */}
        {
          <img
            src={slice.primary?.theme == "dark" ? darkVector : lightVector}
            loading="eager"
            alt="stats"
            className="absolute z-0 hidden top-80 2xl:top-64 lg:block"
          />
        }
        {/* tablet vector */}
        {
          <img
            src={darkVectorTablet}
            loading="eager"
            alt="stats"
            className="absolute z-0 hidden top-72 sm:block lg:hidden"
          />
        }
        {/* mobile vector */}
        {
          <img
            src={darkVectorMobile}
            loading="eager"
            alt="stats"
            className="absolute z-0 block top-52 xs:top-40 sm:hidden"
          />
        }
        <section className="relative px-4 mx-auto sm:px-12 lg:px-5 max-w-7xl">
          <div className="flex flex-col flex-1 gap-3 pt-10 mx-auto lg:pt-20 lg:mx-11">
            {slice?.primary?.caption_title_style === "rounded rectangle" ? (
              <div className="flex items-center gap-2">
                <p
                  className={`inline text-xs font-semibold max-w-fit leading-4 uppercase font-manrope tracking-widest_1 bg_text`}
                >
                  {slice?.primary?.caption_title?.text}
                </p>

                {slice?.primary?.caption_title?.text === "Encord Index" && (
                  <p className="text-purple-350 text-xs  font-manrope font-medium px-2 py-1 tracking-wider  uppercase rounded-[4px]">
                    New
                  </p>
                )}
              </div>
            ) : (
              <p
                className={`text-xs sm:text-base font-medium  leading-4 sm:leading-6 uppercase font-inter tracking-widest_1  pb-3 ${slice.primary?.theme == "dark" ? "text-purple-350" : "text-purple-1500"}`}
              >
                {slice?.primary?.caption_title?.text}
                {slice?.primary?.caption_title?.text === "Encord Index" && (
                  <span className="text-white text-[10px] h-[19px] font-manrope font-medium px-2 py-1 inline bg-purple-350 uppercase rounded-[4px] ml-4">
                    New
                  </span>
                )}
              </p>
            )}
            <div className="flex flex-col gap-5 lg:flex-row lg:gap-10">
              <div className="xl:max-w-[648px] overflow-hidden flex-1 lg:flex-[0.5] xl:flex-1">
                <div
                  className={`text-3.25xl sm:text-4.5xl font-manrope leading-10 sm:leading-12 font-semibold tracking-[-1.8px] sm:tracking-tightest_4 lg:pb-5  ${slice.primary?.theme == "dark" ? "text-white dark_strong_color_style" : "text-gray-2700 strong_color_style"}`}
                  dangerouslySetInnerHTML={{
                    __html: slice?.primary?.title?.html,
                  }}
                />
                {leftBottom && !isEmpty && (
                  <section className="max-w-[400px] xs:max-w-[648px] marquee_padding hidden lg:block">
                    {slice?.primary?.show_logo_caption_title && (
                      <p
                        className={`pt-8 pb-5 text-xs font-semibold  uppercase font-manrope tracking-widest_1 ${slice.primary?.theme == "dark" ? "text-white" : "text-gray-600"}`}
                      >
                        Trusted by pioneering AI Teams
                      </p>
                    )}
                    {logo_marque_data?.items[0]?.brand_image?.url && (
                      <LogoMarquee
                        logoArray={logo_marque_data}
                        logoBackground=""
                      />
                    )}
                  </section>
                )}
                {slice?.primary?.show_cta && (
                  <>
                    {rightBottom && !isEmpty && (
                      <CustomButton
                        onClick={toggleModal}
                        text={slice?.primary?.cta_text?.text}
                        buttonClass={`text-white hover:bg-purple-500 mt-5 hidden lg:block ${slice.primary?.theme == "dark" ? "itk_btn_dark" : "bg-purple-1500"}`}
                      />
                    )}
                  </>
                )}
                {slice?.primary?.show_email_cta && (
                  <>
                    {rightBottom && !isEmpty && (
                      <div className="mx-auto lg:mx-[unset] w-full sm:max-w-md mt-5">
                        <SingleEmailForm2
                          openModal={toggleModal}
                          border={dark}
                          setEmail={setEmail}
                          formHsClass={slice?.primary?.hs_class?.text}
                          formId={slice?.primary?.hs_id?.text}
                          buttonText={slice?.primary?.cta_text?.text}
                        />
                      </div>
                    )}
                  </>
                )}
              </div>
              <div className="flex-[0.5] overflow-hidden ">
                <p
                  className={`text-lg font-normal leading-7 font-inter tracking-tight_1 ${slice.primary?.theme == "dark" ? "text-gray-1400" : "text-gray-600"}`}
                >
                  {slice?.primary?.description?.text}
                </p>
                {rightBottom && !isEmpty && (
                  <section className="max-w-[400px] xs:max-w-[556px] hidden lg:block">
                    {slice?.primary?.show_logo_caption_title && (
                      <p
                        className={`pt-8 pb-5 text-xs font-semibold uppercase font-manrope tracking-widest_1 ${slice.primary?.theme == "dark" ? "text-white" : "text-gray-600"}`}
                      >
                        Trusted by pioneering AI Teams
                      </p>
                    )}
                    {logo_marque_data?.items[0]?.brand_image?.url && (
                      <LogoMarquee
                        logoArray={logo_marque_data}
                        logoBackground=""
                      />
                    )}
                  </section>
                )}
                {slice?.primary.show_cta && (
                  <>
                    {leftBottom && !isEmpty && (
                      <CustomButton
                        onClick={toggleModal}
                        text={slice?.primary?.cta_text?.text}
                        buttonClass={`text-white hover:bg-[#5658dd] mt-5 ${slice.primary?.theme == "dark" ? "itk_btn_dark" : "bg-purple-1500"}`}
                      />
                    )}
                    {rightBottom && !isEmpty && (
                      <CustomButton
                        onClick={toggleModal}
                        text={slice?.primary?.cta_text?.text}
                        buttonClass={`text-white hover:bg-[#5658dd] mt-5 mb-8 block lg:hidden ${slice.primary?.theme == "dark" ? "itk_btn_dark" : "bg-purple-1500"}`}
                      />
                    )}
                  </>
                )}
                {slice?.primary.show_email_cta && (
                  <>
                    {leftBottom && !isEmpty && (
                      <div className="lg:mx-[unset] w-full  sm:max-w-md mt-5">
                        <SingleEmailForm2
                          border={dark}
                          openModal={toggleModal}
                          setEmail={setEmail}
                          formHsClass={slice?.primary?.hs_class?.text}
                          formId={slice?.primary?.hs_id?.text}
                          buttonText={slice?.primary?.cta_text?.text}
                        />
                      </div>
                    )}
                    {rightBottom && !isEmpty && (
                      <div className="lg:mx-[unset] w-full sm:max-w-md mt-5">
                        <SingleEmailForm2
                          border={dark}
                          openModal={toggleModal}
                          setEmail={setEmail}
                          formHsClass={slice?.primary?.hs_class?.text}
                          formId={slice?.primary?.hs_id?.text}
                          buttonText={slice?.primary?.cta_text?.text}
                        />
                      </div>
                    )}
                  </>
                )}
                {!isEmpty && (
                  <section className="max-w-[400px] xs:max-w-[648px] marquee_padding lg:hidden">
                    {slice?.primary?.show_logo_caption_title && (
                      <p className="pt-8 text-xs font-semibold text-gray-600 uppercase font-manrope tracking-widest_1">
                        Trusted by pioneering AI Teams
                      </p>
                    )}
                    {logo_marque_data?.items[0]?.brand_image?.url && (
                      <LogoMarquee logoArray={logo_marque_data} />
                    )}
                  </section>
                )}
              </div>
            </div>
          </div>
        </section>
      </article>
      {/* <div className="relative z-10 px-4 mx-auto sm:px-12 lg:px-5 mt-[-50px] xs:mt-[-100px] lg:mt-[-250px] xl:mt-[-353px] pb-10"> */}
      {slice?.primary?.video_link?.url ? (
        <div className="relative z-10 px-4 mx-auto sm:px-12 lg:px-5 mt-[-50px] xs:mt-[-100px] lg:mt-[-250px] xl:mt-[-353px] pb-10">
          <div
            className={`max-w-4xl mx-auto w-full h-auto rounded-[18px] shadow-card`}
          >
            <VideoComponent
              loop
              autoPlay
              muted
              className={`max-w-4xl mx-auto w-[100%] h-auto lg:rounded-[18px] sm:rounded-2xl rounded-xl shadow-card`}
            >
              <source src={slice?.primary?.video_link?.url} type="video/mp4" />
            </VideoComponent>
          </div>
        </div>
      ) : (
        <>
          {slice?.primary.hero_image?.url ? (
            <div className="relative z-10 px-4 mx-auto sm:px-12 lg:px-5 mt-[-50px] xs:mt-[-100px] lg:mt-[-250px] xl:mt-[-353px] pb-10">
              <img
                src={slice?.primary?.hero_image?.url}
                loading="eager"
                width={0}
                height={0}
                alt="stats"
                className={`max-w-4xl mx-auto w-[100%] h-auto aspect-[19/11] lg:rounded-[18px] sm:rounded-2xl rounded-xl shadow-card`}
              />
            </div>
          ) : (
            <div className="relative z-10 px-4 mx-auto sm:px-12 lg:px-5 mt-[-50px] xs:mt-[-170px] lg:mt-[-300px] xl:mt-[-465px] pb-10">
              <iframe
                src="https://demo.arcade.software/zaEKRcCLPyHUlfU9Wmsc?embed&embed_mobile=tab&embed_desktop=inline&show_copy_link=true"
                title=""
                frameBorder="0"
                loading="lazy"
                allowFullScreen
                allow="clipboard-write"
                className={`z-20 max-w-4xl mx-auto w-[100%] h-auto aspect-[19/11] `}
                style={{
                  width: "100%",
                  height: "100%",
                  colorScheme: "light",
                }}
              />
            </div>
          )}
        </>
      )}
      {/* </div> */}
    </>
  );
};

export default HeroRaphael;
