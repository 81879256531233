import React, { useContext } from "react";
import HeadingSection from "../../Pieces/HeadingSection";
import ModalContext from "../../../Contexts/openModal";

const FeaturePicasso = ({ slice }) => {
  const { toggleModal } = useContext(ModalContext);
  return (
    <section className="relative px-4 mx-auto sm:px-12 lg:px-5 max-w-7xl">
      <article className="pt-10 lg:pt-20 lg:mx-11">
        <HeadingSection
          data={slice?.primary}
          toggleModal={toggleModal}
          sectionStyle="sm:max-w-[648px] tracking-[-0.45px] md:max-w-[1002px] lg:!pb-10"
          descriptionStyle="lg:text-lg lg:tracking-tighter_1"
          textAreaStyle="gap-4"
          headingStyle="sm:text-3.25xl sm:leading-10 sm:!-tracking-[0.9px]"
        />
      </article>
      <div className="flex flex-col gap-7 sm:gap-2.5 xl:px-0 mx-auto sm:flex-row justify-center pb-10 flex-wrap gap-y-3">
        
        {slice?.items?.map((picasso, index) => {
          return (
            <div
              key={index}
              className={`flex flex-col mx-auto gap-3 lg:max-w-[420px] xl:min-w-[516px] xl:max-w-[516px] flex-1 lg:flex-none`}
            >
              <img
                className="min-h-[183px] max-h-[183px] object-scale-down"
                src={picasso?.feature_image?.url}
              />
              <p className="text-purple-1100 font-manrope text-xl tracking-[-0.5px] font-semibold sm:min-h-14 lg:min-h-fit">
                {picasso?.title?.text}
              </p>
              <p className="text-base font-normal text-gray-600 tracking-[-0.4px] flex-1">
                {picasso?.description?.text}
              </p>
            </div>
          );
        })}
      </div>
    </section>
  );
};
export default FeaturePicasso;
